<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23483 aligncenter" src="@/assets/news/18/1.png" alt="图片" width="657" height="435"  sizes="(max-width: 657px) 100vw, 657px"></p>
        <p>“禁止外国人入境”政策持续到2月底</p>
        <p>近日，由奥密克戎变异株扩散，日本首相宣布将布禁止外国旅客入境项政策延长到2月底（除非有特殊原因或因人道主义问题）。</p>
        <p>以下是日本驻华大使馆发布的关于针对奥密克戎毒株的紧急边境延长措施的通知：</p>
        <p>现根据《边境防控强化新政策（23）（令和3年12月28日）》，以及目前正持续实施的《边境防控强化新政策（20）（令和3年11月29日）》，关于停止外国人新规入境的政策，现本馆的签证申请政策调整为以下措施。（注：令和3年即2021年）</p>
        <p>1、签证有效性暂停</p>
        <p>令和3年（2021年）12月2日前发给及交付的签证，除以下列举的签证以外，其余签证有效性暂停至令和4年2月底。（注：令和4年即2022年）</p>
        <p>日本人配偶者等（签证类型为（S）AS.SPOUSE, CHILD OF JAPANESE）</p>
        <p>永住者的配偶者等（（S）AS SPOUSE OF PERMANENT RESIDENT）</p>
        <p>外交（（D）AS DIPLOMAT）</p>
        <p>※若日本人的配偶者/子、永住者的配偶者/子，所持有的签证类型为「短期滞在」（（V）AS TEMPORARY VISITOR），则也将被认定为暂停签证有效性的对象。请注意其无法被承认为有效入国签证。</p>
        <p>2、能被承认的「特定情况」</p>
        <p>（1）持有再入国许可（包含视同再入国许可）的人员，需要再次入国具体详情请参考：出入国在留管理庁HP</p>
        <p>（2）新规入国的人员为以下措施</p>
        <p>在令和2年（2020年）8月31日前持有再入国许可（包括视同再入国许可）出境，前往现被视为禁止入境对象的国家/地区的人，在该国家/地区被认定为禁止入境对象范围后，再在入国许可有效到期前，没有再入境日本的人员。</p>
        <p>日本人/永住者的配偶者及子女</p>
        <p>定住者配偶或子女因家属滞留在日本，处于家人离散的情况的人员</p>
        <p>「外交」/「公用」</p>
        <p>有特别人道主义理由或有高度公益性理由</p>
        <p><img loading="lazy" class="wp-image-23484 aligncenter" src="@/assets/news/18/2.png" alt="图片" width="604" height="217"  sizes="(max-width: 604px) 100vw, 604px"></p>
        <p>3、其他</p>
        <p>再入国已失效的「元永住者」人员，请先与本馆或本馆指定代办机构进行联系咨询。</p>
        <p>4、相关链接</p>
        <p>【外務省】</p>
        <p>日语：https://www.mofa.go.jp/mofaj/ca/fna/page22_003381.html</p>
        <p>英語 ：https://www.mofa.go.jp/ca/fna/page22e_000921.html</p>
        <p>【出入国在留管理庁】</p>
        <p>日語：https://www.moj.go.jp/isa/content/001347330.pdf</p>
        <p>英語 ：https://www.moj.go.jp/isa/content/001361129.pdf</p>
        <p><img loading="lazy" class="wp-image-23485 aligncenter" src="@/assets/news/18/3.png" alt="图片" width="668" height="552"  sizes="(max-width: 668px) 100vw, 668px"></p>
        <p>&nbsp;</p>
        <p>此外，日本政府1月14日宣布，新冠疫情边境防控措施中要求入境人员居家观察的时间从14天缩短到10天。适用对象为来自全世界的入境人员，15日0点开始实行。随着调整新冠变异毒株“奥密克戎”感染者的密切接触者的观察期而缩短了期限。</p>
        <p>确认入境人员健康状况和禁止乘坐公共交通的措施的期限也从14天缩短为10天。如果情况发生变化，比如有国家出现新的变异毒株疫情，则另行针对该地区要求观察14天。</p>
        <p>虽然日本政府在2月底之前原则上禁止外国人的入境，但持有日本长期居留身份的外国人只要遵循以上的新的隔离政策，就可以自由出入日本，在身份有效期内无停留时间限制，并享受与日本人同等的社会福利。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news18",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:18,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>